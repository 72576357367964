import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from 'react-redux';
import { Router as BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { logoutUser, setCurrentUser } from '../Authentication/authSlice';
import store from './store';

import Router from './Router';
import ScrollToTop from './ScrollToTop';
import ModalController from '../../connectors/ModalController';
import theme from '../../utils/theme';
import { STATUS_ACTIVE, STATUS_UNVERIFIED } from '../../utils/constants';
import { request } from '../../utils/requestSaga';
import history from './history';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'video.js/dist/video-js.css';
import { accessDeepObject } from '../../utils/commonHelpers';
import { NavigationProvider } from '../../context/navigationContext';

toast.configure();

if (localStorage.jwtToken) {
	// Decode token and get user info and exp
	const decoded = jwtDecode(localStorage.jwtToken);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser({ user: decoded }));

	// check user's status and logout if user's status
	// is not UNVERIFIED or ACTIVE
	request('GET', `${process.env.REACT_APP_API_URL}/v1/admin/me`).then((res) => {
		if (![STATUS_ACTIVE, STATUS_UNVERIFIED].includes(accessDeepObject('data.status', res))) {
			store.dispatch(logoutUser());
		}
	});

	// Check for expired token
	const currenTime = Date.now() / 1000;
	if (decoded.exp < currenTime) {
		// Logout user
		store.dispatch(logoutUser());
		// Redirect to login
		window.location.href = '/login';
	}
}

class App extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			loaded: false,
		};
	}

	componentDidMount() {
		window.addEventListener('load', () => {
			this.setState({ loading: false });
			setTimeout(() => this.setState({ loaded: true }), 500);
		});
	}

	render() {
		const { loaded, loading } = this.state;
		return (
			<Provider store={store}>
				<BrowserRouter history={history}>
					<NavigationProvider>
						<ScrollToTop>
							<ThemeProvider theme={theme}>
								<>
									<ModalController />
									{!loaded && (
										<div className={`load${loading ? '' : ' loaded'}`}>
											<div className="load__icon-wrap">
												<svg className="load__icon">
													<path fill="#f05a28" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
												</svg>
											</div>
										</div>
									)}
									<div>
										<Router />
									</div>
								</>
							</ThemeProvider>
						</ScrollToTop>
					</NavigationProvider>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
