import React, { useRef, useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { actionSpreader, isNotEmptyArray, ObjectMaybe, parseQueryParams, stringifyQueryParams } from 'utils/commonHelpers';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import { useNavigation } from 'context/navigationContext';
import QuestionPreview from 'containers/Exercise/components/QuestionPreview';
import {
	deleteLessonQuestion,
	fetchLesson,
	fetchLessonQuestions,
	reorderLessonQuestions,
	toggleLessonQuestionStatus,
} from '../actions/lessons.actions';
import { ContentDocumentStatusEnum, DocumentStatusEnum, QuestionTypesEnum, TQuestion } from '../utils/types';
import { questionDisplayFormatter } from '../utils/questionListHelpers';
import { setApiParams, setPage } from '../LessonsSlice';

const heads = [
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'roleName',
		Header: 'Role Name',
	},
	{
		accessor: 'voiceId',
		Header: 'Role Voice',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'type',
		Header: 'Question Type',
	},
	{ accessor: 'status', Header: 'Status' },
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({
	id: questionId,
	idx,
	totalQuestions,
	isSimilarQuestion,
	status,
	lessonId,
	onPreviewQuestion,
	onMoveUpQuestion,
	onMoveDownQuestion,
	showPlayQuestion,
	postStatusChange,
	adminCode,
}: Pick<TQuestion, 'id' | 'status'> & {
	lessonId: string;
	idx: number;
	isSimilarQuestion: boolean;
	totalQuestions: number;
	onPreviewQuestion: () => void;
	onMoveUpQuestion: () => void;
	onMoveDownQuestion: () => void;
	postStatusChange: () => void;
	showPlayQuestion: boolean;
	adminCode: string;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { navigate } = useNavigation();
	const dispatch = useDispatch();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<>
					<DropdownItem
						onClick={() =>
							// window.open(`/lessons/questions/add-edit${stringifyQueryParams({ questionId, lessonId, mode: 'edit' })}`, '_blank')
							navigate(`/lessons/questions/add-edit${stringifyQueryParams({ questionId, lessonId, mode: 'edit' })}`)
						}
						disabled={false}
					>
						Edit question
					</DropdownItem>
					<DropdownItem
						onClick={() => {
							dispatch(toggleLessonQuestionStatus({ questionId, lessonId, postStatusChange }));
						}}
						disabled={false}
					>
						Toggle status
					</DropdownItem>
					{idx !== 0 && (
						<DropdownItem onClick={onMoveUpQuestion} disabled={false}>
							Move Up
						</DropdownItem>
					)}
					{idx !== totalQuestions - 1 && (
						<DropdownItem onClick={onMoveDownQuestion} disabled={false}>
							Move Down
						</DropdownItem>
					)}
					<DropdownItem onClick={onPreviewQuestion} disabled={false}>
						Show Preview
					</DropdownItem>
					<DropdownItem
						onClick={() => {
							dispatch(deleteLessonQuestion({ questionId, lessonId }));
						}}
						disabled={false}
					>
						Delete permanently
					</DropdownItem>
				</>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({
	questions,
	lessonId,
	postStatusChange,
	isSimilarQuestion,
	onPreviewQuestion,
	onMoveUpQuestion,
	onMoveDownQuestion,
	showPlayQuestion,
}: {
	questions: Array<TQuestion & { questionStatus?: DocumentStatusEnum }>;
	lessonId: string;
	isSimilarQuestion: boolean;
	postStatusChange;
	onPreviewQuestion;
	onMoveUpQuestion;
	onMoveDownQuestion;
	showPlayQuestion: boolean;
}) => {
	return questions.map((questionDetail, idx) => {
		const { id, adminCode, type, status, questionStatus } = questionDetail;

		let roleName;
		if (questionDetail.type === QuestionTypesEnum.CONTENT_ONLY) {
			roleName = questionDetail.roleName;
		}

		const title = questionDisplayFormatter(questionDetail);

		return {
			id,
			title,
			roleName,
			voiceId: questionDetail.voiceId,
			adminCode,
			type,
			status,
			questionStatus,
			actions: (
				<Actions
					id={id}
					idx={idx}
					isSimilarQuestion={isSimilarQuestion}
					totalQuestions={questions.length}
					status={status}
					lessonId={lessonId}
					onPreviewQuestion={() => onPreviewQuestion(id)}
					onMoveUpQuestion={() => onMoveUpQuestion(id, idx)}
					onMoveDownQuestion={() => onMoveDownQuestion(id, idx)}
					showPlayQuestion={showPlayQuestion}
					adminCode={adminCode}
					postStatusChange={postStatusChange}
				/>
			),
			onClick: () => {
				onPreviewQuestion(id);
			},
		};
	});
};

const LessonQuestionList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const lessonsState = useSelector(({ lessons }) => ({
		questions: lessons.questions,
		additionalData: lessons.additionalData,
		isLoading: lessons.isLoading,
		isSubmitting: lessons.isSubmitting,
		apiParams: lessons.apiParams,
		total: lessons.total,
		page: lessons.page,
		lesson: lessons.lesson,
	}));

	const { navigate } = useNavigation();

	const [lessonsRow, setLessonsRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [showPlayQuestion, setShowPlayQuestion] = useState(false);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		dispatch(fetchLesson({ lessonId: qp.lessonId }));

		dispatch(
			fetchLessonQuestions({
				...lessonsState.apiParams,
				...(qp.siblingId && { sortOrder: '1', sortKey: 'rank' }),
				lessonId: qp.lessonId,
				subtopicId: qp.subtopicId,
				siblingId: qp.siblingId,
			})
		);
	}, [lessonsState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(lessonsState.page);
	}, [lessonsState.page]);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Lesson',
						url: `/lessons/list${stringifyQueryParams({})}`,
					},
					{
						text: 'Questions',
						url: `/lessons/questions/list${stringifyQueryParams({ lessonId: qp.lessonId })}`,
					},
				],
			})
		);

		if (isNotEmptyArray(lessonsState.questions)) {
			const structuredRows = createNewRows({
				questions: lessonsState.questions,
				isSimilarQuestion: qp.mode === 'similar',
				lessonId: qp.lessonId,
				postStatusChange: () => {
					dispatch(
						fetchLessonQuestions({
							...lessonsState.apiParams,
							...(qp.siblingId && { sortOrder: '1', sortKey: 'rank' }),
							lessonId: qp.lessonId,
							subtopicId: qp.subtopicId,
							siblingId: qp.siblingId,
						})
					);
				},
				onPreviewQuestion: (questionId) => {
					questionPreviewRef.current?.show?.(questionId);
				},
				onMoveUpQuestion: (questionId, idx) => {
					dispatch(reorderLessonQuestions({ currentIdx: idx, move: 'UP', lessonId: qp.lessonId }));
				},
				onMoveDownQuestion: (questionId, idx) => {
					dispatch(reorderLessonQuestions({ currentIdx: idx, move: 'DOWN', lessonId: qp.lessonId }));
				},
				showPlayQuestion,
			});

			setLessonsRow(structuredRows);
		} else {
			setLessonsRow([]);
		}
	}, [lessonsState.questions]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = lessonsState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	const questionPreviewRef = useRef<any>();
	const qp = ObjectMaybe(parseQueryParams(props.location.search));

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						<h3>Lesson Questions Listing</h3>
					</Col>
					{qp.lessonId && (
						<Col sm={6}>
							<Button
								onClick={() =>
									// window.open(`/lessons/questions/add-edit${stringifyQueryParams({ lessonId: qp.lessonId, mode: 'add' })}`, '_blank')
									navigate(`/lessons/questions/add-edit${stringifyQueryParams({ lessonId: qp.lessonId, mode: 'add' })}`)
								}
							>
								Add a new Question
							</Button>
						</Col>
					)}
					{lessonsState?.lesson && (
						<Col sm={12}>
							<h5>
								<b>Course :</b> {lessonsState?.lesson?.course?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
								{lessonsState?.lesson?.course?.adminCode}
							</h5>
							<h5>
								<b>Topic :</b> {lessonsState?.lesson?.topic?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
								{lessonsState?.lesson?.topic?.adminCode}
							</h5>
							<h5>
								<b>UI Type :</b> {lessonsState?.lesson?.uiType}
							</h5>
						</Col>
					)}
				</Row>

				{isNotEmptyArray(lessonsRow) && (
					<>
						<DataPaginationTable heads={heads} rows={lessonsRow} />
						<Pagination
							itemsCount={lessonsState.total}
							itemsToShow={lessonsState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{lessonsState.total === 0 && <div className="">No data</div>}
			</CardBody>
			<QuestionPreview ref={questionPreviewRef} />
		</Card>
	);
};

export default LessonQuestionList;
