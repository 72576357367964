import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface NavigationContextValue {
	navigate: (path: string, options?: { replace?: boolean }) => void;
}

const NavigationContext = createContext<NavigationContextValue>({
	navigate: () => console.warn('Navigation used outside NavigationProvider'),
});

interface NavigationProviderProps {
	children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
	const history = useHistory();

	const contextValue = useMemo(
		() => ({
			navigate: (path: string, options?: { replace?: boolean }) => {
				if (!path) {
					console.warn('Navigation path is undefined');
					return;
				}

				try {
					if (options?.replace) {
						history.replace(path);
					} else {
						history.push(path);
					}
				} catch (error) {
					console.error('Navigation error:', error);
				}
			},
		}),
		[history]
	);

	return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};

export const useNavigation = (): NavigationContextValue => {
	const context = useContext(NavigationContext);
	if (!context) {
		throw new Error('useNavigation must be used within a NavigationProvider');
	}
	return context;
};
