import React, { useState, useRef } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import { isNotEmptyArray, ObjectMaybe, parseQueryParams, stringifyQueryParams } from 'utils/commonHelpers';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from 'context/navigationContext';
import { DocumentStatusEnum, QuestionTypesEnum, TQuestion } from 'containers/Lessons/utils/types';
import { questionDisplayFormatter } from 'containers/Lessons/utils/questionListHelpers';
import { HangingQuestionTypes } from 'containers/Exercise/utils/helpers';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { fetchHangingQuestions } from './actions/questions.actions';
import { setApiParams, setPage } from './QuestionsSlice';

const heads = [
	{
		accessor: 'order',
		Header: 'Order',
	},
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'type',
		Header: 'Question Type',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({
	id: questionId,
	idx,
	totalQuestions,
	isSimilarQuestion,
	status,
	onPreviewQuestion,
	postStatusChange,
	adminCode,
}: Pick<TQuestion, 'id' | 'status'> & {
	idx: number;
	isSimilarQuestion: boolean;
	totalQuestions: number;
	onPreviewQuestion: () => void;
	postStatusChange: () => void;
	adminCode: string;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { navigate } = useNavigation();
	const dispatch = useDispatch();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<>
					<DropdownItem
						onClick={() => navigate(`/lessons/questions/add-edit${stringifyQueryParams({ questionId, hang: 'true', mode: 'edit' })}`)}
						disabled={false}
					>
						Edit question
					</DropdownItem>
				</>
			</DropdownMenu>
		</Dropdown>
	);
};

const getOrderFromQuestion = ({ questionDetail }: { questionDetail: TQuestion }): string | number => {
	switch (questionDetail.type) {
		case QuestionTypesEnum.CROSSWORD:
			return questionDetail.level;
		case QuestionTypesEnum.MCQ:
			return questionDetail.adminCode.replace('spx_ques_grammar_', '');
		default:
			return '';
	}
};

const createNewRows = ({
	questions,
	postStatusChange,
	isSimilarQuestion,
	onPreviewQuestion,
	showPlayQuestion,
	onSortOrderChange,
}: {
	questions: Array<TQuestion & { questionStatus?: DocumentStatusEnum }>;
	isSimilarQuestion: boolean;
	postStatusChange;
	onPreviewQuestion: (questionId: string) => void;
	showPlayQuestion: boolean;
	onSortOrderChange: () => void;
}) => {
	return questions.map((questionDetail, idx) => {
		const { id, adminCode, type, status, questionStatus } = questionDetail;

		let roleName;
		if (questionDetail.type === QuestionTypesEnum.CONTENT_ONLY) {
			roleName = questionDetail.roleName;
		}

		const title = questionDisplayFormatter(questionDetail);

		const order = getOrderFromQuestion({
			questionDetail,
		});

		return {
			id,
			order,
			title,
			roleName,
			voiceId: questionDetail.voiceId,
			adminCode,
			type,
			status,
			questionStatus,
			actions: (
				<Actions
					id={id}
					idx={idx}
					isSimilarQuestion={isSimilarQuestion}
					totalQuestions={questions.length}
					status={status}
					onPreviewQuestion={() => onPreviewQuestion(id)}
					adminCode={adminCode}
					postStatusChange={postStatusChange}
				/>
			),
		};
	});
};

const questionTypeToAdminCodeRegex = (type: QuestionTypesEnum): string => {
	switch (type) {
		case QuestionTypesEnum.CROSSWORD:
			return 'spx_ques_crossword';
		case QuestionTypesEnum.MCQ:
			return 'spx_ques_grammar';
		default:
			return null;
	}
};

const QuestionsList = (props) => {
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const dispatch = useDispatch();
	const questionType = watch('type');

	// const breadcrumbsData = useSelector(({ global }) => ({
	// 	breadcrumbs: global.breadcrumbs,
	// }));

	const lessonsState = useSelector(({ question }) => ({
		questions: question.questions,
		additionalData: question.additionalData,
		isLoading: question.isLoading,
		isSubmitting: question.isSubmitting,
		apiParams: question.apiParams,
		total: question.total,
		page: question.page,
		lesson: question.lesson,
	}));

	const { navigate } = useNavigation();

	const [lessonsRow, setLessonsRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [showPlayQuestion, setShowPlayQuestion] = useState(false);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		dispatch(
			fetchHangingQuestions({
				...lessonsState.apiParams,
				adminCodeRegex: questionTypeToAdminCodeRegex(questionType?.value),
			})
		);
	}, [lessonsState.apiParams, questionType]);

	React.useEffect(() => {
		setPageOfItems(lessonsState.page);
	}, [lessonsState.page]);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		// dispatch(
		// 	actionSpreader(setBreadcrumbs.type, {
		// 		breadcrumbs: [
		// 			{
		// 				text: 'Lesson',
		// 				url: `/lessons/list${stringifyQueryParams({})}`,
		// 			},
		// 			{
		// 				text: 'Questions',
		// 				url: `/lessons/questions/list${stringifyQueryParams({ lessonId: qp.lessonId })}`,
		// 			},
		// 		],
		// 	})
		// );

		if (isNotEmptyArray(lessonsState.questions)) {
			const structuredRows = createNewRows({
				questions: lessonsState.questions,
				isSimilarQuestion: qp.mode === 'similar',
				postStatusChange: () => {
					dispatch(
						fetchHangingQuestions({
							...lessonsState.apiParams,
							adminCodeRegex: questionTypeToAdminCodeRegex(questionType?.value),
						})
					);
				},
				onPreviewQuestion: (questionId) => {
					questionPreviewRef.current?.show?.(questionId);
				},
				showPlayQuestion,
				onSortOrderChange,
			});

			setLessonsRow(structuredRows);
		} else {
			setLessonsRow([]);
		}
	}, [lessonsState.questions]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = lessonsState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	const onSortOrderChange = () => {
		const { apiParams } = lessonsState;

		let sortKey = '_id';

		switch (questionType?.value) {
			case QuestionTypesEnum.CROSSWORD:
				sortKey = 'level';
				break;
			case QuestionTypesEnum.MCQ:
				sortKey = '_id';
				break;
			default:
				sortKey = '_id';
		}

		dispatch(
			setApiParams({
				...apiParams,
				sortOrder: apiParams.sortOrder === '-1' ? '1' : '-1',
				sortKey,
			})
		);
	};

	const questionPreviewRef = useRef<any>();
	const qp = ObjectMaybe(parseQueryParams(props.location.search));

	return (
		<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
			<Card>
				<LoadingOverlay isLoading={lessonsState.isLoading} />

				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={6}>
							<h3>Hanging Questions</h3>
						</Col>
						<Col sm={6}>
							<Button onClick={() => navigate(`/lessons/questions/add-edit${stringifyQueryParams({ hang: 'true', mode: 'add' })}`)}>
								Add a new Question
							</Button>
						</Col>
					</Row>

					<Row>
						<Col style={{ maxWidth: '250px' }}>
							<SelectFieldNew
								label="Select Question Type"
								name="type"
								options={HangingQuestionTypes}
								defaultValue={HangingQuestionTypes[0]}
							/>
						</Col>
					</Row>

					{isNotEmptyArray(lessonsRow) && (
						<>
							<DataPaginationTable heads={heads} rows={lessonsRow} onSort={onSortOrderChange} />
							<Pagination
								itemsCount={lessonsState.total}
								itemsToShow={lessonsState.apiParams.limit}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{lessonsState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default QuestionsList;
