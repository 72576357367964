import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { isNotEmptyObject, showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading, setUserDetails } from '../userSlice';

export const getAdminMentors = createAsyncThunk('user/getAdminMentors', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/admins?isMentor=true', {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				mentors: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const getAdmins = createAsyncThunk('user/getAdmins', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/admins', {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				admins: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const grantUserSubscription = createAsyncThunk(
	'user/grantMembership',
	async ({ userId, adminUserId, numDays }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		try {
			const payload = {
				refereeUserId: userId,
				adminUserId,
				numDays,
			};

			const res = await fetchWrapper('/v1/admin/user/grant-membership', {
				method: 'POST',
				body: payload,
			});

			if (res.status !== 200) {
				dispatch({ type: toggleLoading.type, payload: { loading: false } });
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Subscription days added succcessfully!!`);
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);

export const getUser = createAsyncThunk('getUser', async ({ searchTerm }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	try {
		const res = await fetchWrapper('/v1/admin/users', {
			method: 'POST',
			body: {
				...(searchTerm.startsWith('+91-')
					? { mobile: searchTerm }
					: {
							userId: searchTerm,
					  }),
			},
		});

		const [dbPackages, ordersAndPayments] = await Promise.all([
			fetchWrapper('/admin/get-packages-for-admin', {
				method: 'GET',
			}),
			fetchWrapper('/admin/orders-and-payments', {
				method: 'POST',
				body: {
					userId: res.data.userData.id,
				},
			}),
		]);

		if (!isNotEmptyObject(res?.data?.userData)) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
			showToast('error', `No user found`);
		} else {
			const isAppAtLeast500 = res.data.userData['latestAndroidAppVersion'] >= 500;
			if (!isAppAtLeast500) {
				showToast('warn', `You tried searching an old app user`);
			} else {
				dispatch(setUserDetails({ ...res.data.userData, dbPackages: dbPackages.data, payments: ordersAndPayments.data }));
				console.log({ ...res.data.userData, dbPackages: dbPackages.data, payments: ordersAndPayments.data });
				showToast('success', `👍️ Found user succcessfully!!`);
			}
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const adminGrantUser = createAsyncThunk(
	'grant-package-via-admin',
	async ({ userId, loggedInUserMail, cart, duration, adminGrantReason }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		try {
			if (duration <= 0) {
				showToast('error', 'Duration must be positive');
				return;
			}

			const res = await fetchWrapper('/v1/admin/grant-package-via-admin', {
				method: 'POST',
				body: {
					amount: 0,
					createdBy: loggedInUserMail,
					userId,
					paymentSource: 'ADMIN',
					adminGrantReason,
					cart,
					duration,
				},
			});

			if (res.status !== 200) {
				dispatch({ type: toggleLoading.type, payload: { loading: false } });
				dispatch({ type: setError.type, payload: { errors: res.errors } });
				showToast('error', `Unable to grant user package`);
				dispatch(
					getUser({
						searchTerm: userId,
					})
				);
			} else {
				dispatch(setUserDetails(res.data.userData));
				showToast('success', `👍️ Successfully granted!!`);
				dispatch(
					getUser({
						searchTerm: userId,
					})
				);
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const cancelSubscriptionViaAdmin = createAsyncThunk(
	'cancel-subscription-via-admin',
	async ({ subscriptionId, userId, rzpCustomerId }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		try {
			const res = await fetchWrapper('/admin/cancel-subscription-via-admin', {
				method: 'POST',
				body: {
					subscriptionId,
					userId,
					rzpCustomerId,
				},
			});

			if (!res?.data?.data?.cancelResponse?.status) {
				dispatch({ type: toggleLoading.type, payload: { loading: false } });
				dispatch({ type: setError.type, payload: { errors: res.errors } });
				showToast('error', `Unable to cancel subscription`);
				dispatch(
					getUser({
						searchTerm: userId,
					})
				);
			} else {
				dispatch(setUserDetails(res.data.userData));
				showToast('success', `👍️ Successfully cancelled subscription!!`);
				dispatch(
					getUser({
						searchTerm: userId,
					})
				);
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const refundViaAdmin = createAsyncThunk('refund-via-admin', async ({ pgOrderId, userId }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	try {
		const res = await fetchWrapper('/admin/refund-via-admin', {
			method: 'POST',
			body: {
				pgOrderId,
			},
		});

		if (!res?.data?.status) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
			showToast('error', `Unable to refund`);
			dispatch(
				getUser({
					searchTerm: userId,
				})
			);
		} else {
			dispatch(setUserDetails(res.data.userData));
			showToast('success', `👍️ Successfully refunded!!`);
			dispatch(
				getUser({
					searchTerm: userId,
				})
			);
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});
