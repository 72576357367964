export const extractWordsWithIndices = (matrix, minLength = 2) => {
	const rows = matrix.length;
	const cols = matrix[0].length;
	const words = [];

	const addWord = (word, startIdx, endIdx) => {
		if (word.length >= minLength) {
			words.push({
				word,
				startIdx,
				endIdx,
			});
		}
	};

	for (let i = 0; i < rows; i += 1) {
		let word = '';
		let startIdx = null;

		for (let j = 0; j < cols; j += 1) {
			if (matrix[i][j] !== '') {
				if (!startIdx) {
					startIdx = [i, j];
				}
				word += matrix[i][j];
			} else {
				if (word) {
					addWord(word, startIdx, [i, j - 1]);
				}
				word = '';
				startIdx = null;
			}
		}

		if (word) {
			addWord(word, startIdx, [i, cols - 1]);
		}
	}

	for (let j = 0; j < cols; j += 1) {
		let word = '';
		let startIdx = null;

		for (let i = 0; i < rows; i += 1) {
			if (matrix[i][j] !== '') {
				if (!startIdx) {
					startIdx = [i, j];
				}
				word += matrix[i][j];
			} else {
				if (word) {
					addWord(word, startIdx, [i - 1, j]);
				}
				word = '';
				startIdx = null;
			}
		}

		if (word) {
			addWord(word, startIdx, [rows - 1, j]);
		}
	}

	return words;
};

export const getUniqueAlphabets = (words: Array<string>) => {
	const alphabetMap = new Map<string, number>();

	words.forEach((word) => {
		const alphabetFrequency = new Map<string, number>();
		// eslint-disable-next-line no-restricted-syntax
		for (const char of word) {
			alphabetFrequency.set(char, (alphabetFrequency.get(char) ?? 0) + 1);

			alphabetMap.set(char, Math.max(alphabetFrequency.get(char) ?? 0, alphabetMap.get(char) ?? 0));
		}
	});

	return Array.from(alphabetMap).flatMap(([key, value]) => Array(value).fill(key));
};

export const hasRepeatedWords = (str: string): boolean => {
	const words = str.split(',');
	const uniqueWords = new Set(words);
	return words.length !== uniqueWords.size;
};
