import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omitWrapper } from 'utils/commonHelpers';
import { TLesson } from 'containers/Lessons/utils/types';
import {
	fetchLesson,
	fetchLessonQuestion,
	fetchHangingQuestions,
	setLessonStatus,
	updateLessonQuestion,
} from './actions/questions.actions';
import { TQuestionsState } from './utils/types';

type TLessonReducerType<T> = CaseReducer<TQuestionsState, PayloadAction<T>>;

type TLessonReducers = {
	setIsLoading: TLessonReducerType<void>;
	setIsLoaded: TLessonReducerType<void>;
	setError: TLessonReducerType<string>;
	setApiParams: TLessonReducerType<TQuestionsState['apiParams']>;
	setPage: TLessonReducerType<number>;
	toggleIsSubmitting: TLessonReducerType<{ isSubmitting: boolean }>;
};

const questionsSlice = createSlice<TQuestionsState, TLessonReducers>({
	name: 'questions',
	initialState: {
		questions: [],
		additionalData: {},
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 50,
			skip: 0,
			query: '',
			sortKey: 'level',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		// Fetch Lesson Questions
		builder
			.addCase(fetchHangingQuestions.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchHangingQuestions.fulfilled, (state, { payload }) => {
				if (payload?.questions && Array.isArray(payload.questions)) {
					state.questions = payload.questions;
					state.additionalData = omitWrapper(['questions', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});

		// Set Lesson Status
		builder
			.addCase(setLessonStatus.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(setLessonStatus.fulfilled, (state) => {
				state.isLoading = false;
			});

		builder
			.addCase(fetchLessonQuestion.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchLessonQuestion.fulfilled, (state, { payload }) => {
				const { lessonQuestion } = payload ?? {};
				if (lessonQuestion) {
					state.questions = [lessonQuestion, ...state.questions];
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			});

		builder
			.addCase(fetchLesson.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchLesson.fulfilled, (state, { payload }) => {
				const { lesson } = payload ?? {};
				if (lesson) {
					state.lesson = lesson as TLesson;
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			});

		builder
			.addCase(updateLessonQuestion.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateLessonQuestion.fulfilled, (state, { payload }) => {
				const { updatedQuestion } = payload ?? {};
				if (updatedQuestion) {
					const index = state.questions.findIndex((question) => question.id === updatedQuestion.id);
					if (index) {
						const questions = [...state.questions];
						questions[index] = updatedQuestion;
						state.questions = questions;
					} else {
						state.questions = [updatedQuestion, ...state.questions];
					}
				}
				state.isLoading = false;
			});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = questionsSlice.actions;

export default questionsSlice.reducer;
