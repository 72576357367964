import { useNavigation } from 'context/navigationContext';
import React from 'react';
import Box from 'reusableComponents/Box';

const Breadcrumbs = ({ breadcrumbs }) => {
	const { navigate } = useNavigation();

	const handleClick = (event, url) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (url) {
			navigate(url);
		}
	};

	return (
		<Box d="flex" alignItems="center" onClick={(e) => e.stopPropagation()}>
			{breadcrumbs?.map((b, idx) => (
				<React.Fragment key={idx}>
					<Box
						as="span"
						cursor={b.url ? 'pointer' : 'auto'}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleClick(e, b.url);
						}}
						style={{
							cursor: b.url ? 'pointer' : 'auto',
							fontWeight: idx !== breadcrumbs.length - 1 ? 'bold' : 'normal',
							color: idx !== breadcrumbs.length - 1 ? '#2e2e2e' : '#5c5c5c',
						}}
					>
						{b.text}
					</Box>
					{idx !== breadcrumbs.length - 1 && <>&nbsp;/&nbsp;</>}
				</React.Fragment>
			))}
		</Box>
	);
};

export default Breadcrumbs;
