import { createSlice } from '@reduxjs/toolkit';

import { isNotEmptyObject } from '../../utils/commonHelpers';
import history from '../App/history';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		user: null,
		loading: false,
	},
	reducers: {
		mutateLoading: (state, action) => {
			state.loading = action.payload;
		},
		loginUser: (state) => {
			state.loading = true;
		},
		registerUser: (state) => {
			state.loading = true;
		},
		forgotPassword: (state) => {
			state.loading = true;
		},
		resetPassword: (state) => {
			state.loading = true;
		},
		authSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = isNotEmptyObject(action.payload.user);
			state.user = action.payload.user;
		},
		setCurrentUser: (state, action) => {
			state.isAuthenticated = isNotEmptyObject(action.payload.user);
			state.user = action.payload.user;
		},
		logoutUser: {
			reducer: (state) => {
				state.isAuthenticated = false;
				state.user = null;
			},
			prepare: () => {
				alert('You will be logged out');
				localStorage.removeItem('jwtToken');
				history.push('/login');
				return {};
			},
		},
	},
});

export const {
	loginUser,
	registerUser,
	logoutUser,
	authSuccess,
	authError,
	setCurrentUser,
	mutateLoading,
	forgotPassword,
	resetPassword,
} = authSlice.actions;

export default authSlice.reducer;
