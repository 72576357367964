import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { InputNew as Input, InputNew } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import If from 'reusableComponents/If';
import styled from 'styled-components';
import { fetchWrapper } from 'utils/fetchWrapper';
import Box from '../../../reusableComponents/Box';
import CustomModal from '../../../reusableComponents/CustomModal';
import { copyToClipBoard, isNotEmptyArray, showToast } from '../../../utils/commonHelpers';
import theme from '../../../utils/theme';
import { createOrUpdateProfileHelper, getFilteredProfileDetails, getValidCartItems, updateProfileForGrades } from '../helper';
import { ProfileSubjectSelectionCards } from './ProfileSubjectSelectionCards';

const StyledModal = styled.div`
	width: 60vw;
	height: 90vh;
	display: relative;
	padding: 1rem;

	input {
		border: 1px solid #dddddd;
	}
	overflow-y: scroll;
`;

const customStyles = `
	width: 80vh;

	@media ${theme.device.md} {
		width: 80vh;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

interface CreatePaymentLinkFormValues {
	amount: number;
	mobile: string;
	grantSubscriptionMobile: string;
	email?: string;
	fullName?: string;
	createdBy: string;
	description?: string;
	paymentLinkUrl?: string;
	selectedProfile: string;
	isRecurring: boolean;
	selectedGrade: {
		label: string;
		value: string;
	};
	profileName: string;
}

const CreatePaymentLinkModal = ({ userDetails, loggedInUser, closeModal }) => {
	const [paymentLinkUrl, setPaymentLinkUrl] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const dispatch = useDispatch();
	const [packages, setPackages] = useState([]);

	const { id: userId, mobile } = userDetails;

	const hookFormMethods = useForm<CreatePaymentLinkFormValues>({
		defaultValues: {
			mobile,
			grantSubscriptionMobile: mobile,
			createdBy: loggedInUser?.email,
		},
	});

	const { handleSubmit, watch, setValue, getValues } = hookFormMethods;

	const isDisabled = paymentLinkUrl || isSubmitting;

	const formValues = watch(['selectedProfile', 'isRecurring', 'selectedGrade', 'amount']);

	const [profiles, setProfiles] = useState([]);
	const [grades, setGrades] = useState([]);
	const [subscriptionBuckets, setSubscriptionBuckets] = useState([]);

	const amountInCaseOfRecurring = useRef<number>();

	const loadGradesAndProfiles = useCallback(async () => {
		try {
			const [gradesResponse, profilesResponse, packagesResponse, subscriptionBucketsResponse] = await Promise.all([
				fetchWrapper('/v1/admin/get-grades', { method: 'GET' }),
				fetchWrapper(`/v1/admin/profiles?parentId=${userId}`, { method: 'GET' }),
				fetchWrapper(`/v1/admin/membership/get-packages-by-grade?&entityType=PACKAGE`, {
					method: 'GET',
				}),
				fetchWrapper('/v1/admin/membership/get-subscription-buckets', { method: 'GET' }),
			]);

			const grades = gradesResponse.data;
			if (grades) {
				setGrades(grades);
			}

			const profiles = profilesResponse.data;
			if (profiles) {
				setProfiles(profiles);
			}

			const packages = packagesResponse.data;
			if (packages) {
				setPackages(packages);
			}

			const subscriptionBuckets = subscriptionBucketsResponse.data;
			if (subscriptionBuckets) {
				setSubscriptionBuckets(subscriptionBuckets);
			}

			if (![gradesResponse.status, profilesResponse.status, packagesResponse.status, subscriptionBucketsResponse.status].includes(200)) {
				throw new Error('Failed to load grades, profiles, subscription buckets or packages');
			}
		} catch (error) {
			showToast('error', 'Failed to load grades and profiles');
		}
	}, []);

	useEffect(() => {
		loadGradesAndProfiles();
		loadPackages();
		setValue('isRecurring', true);
	}, []);

	const loadPackages = useCallback(async () => {
		try {
			const packagesResponse = await fetchWrapper(`/v1/admin/membership/get-packages-by-grade?&entityType=PACKAGE`, {
				method: 'GET',
			});
			if (packagesResponse.status !== 200) {
				throw new Error('Failed to load packages');
			}
			const packages = packagesResponse.data;
			if (packages) {
				setPackages(packages);
			}
		} catch (error) {
			showToast('error', 'Failed to load packages and reference ids');
		}
	}, []);

	useEffect(() => {
		setValue('selectedProfile', null);
	}, [formValues.isRecurring]);

	const onSubmit = async (values) => {
		try {
			setIsSubmitting(true);
			await updateProfileForGrades(values, userId);
			const validCartItems = getValidCartItems(
				formValues.isRecurring ? [profiles.find((profile) => profile.id === formValues.selectedProfile)] : activeProfiles,
				values,
				packages,
				formValues.isRecurring
			);
			if (!isNotEmptyArray(validCartItems)) {
				return;
			}
			const res = await fetchWrapper(`/v2/admin/create-payment-link`, {
				method: 'POST',
				body: {
					amount: values.amount,
					fullName: values.fullName,
					email: values.email,
					mobile: values.mobile,
					description: values.description,
					createdBy: values.createdBy,
					isRecurring: values.isRecurring,
					userId,
					cart: validCartItems,
					expireBy: Math.round(Number(values.expireBy) * 3600 + new Date().getTime() / 1000),
					subscriptionBucketName: values.subscriptionBucketName?.value,
				},
			});
			if (res.status === 200) {
				showToast('success', 'Payment link copied to clipboard!');
				setPaymentLinkUrl(res.data?.paymentLinkUrl);
				amountInCaseOfRecurring.current = res.data?.amount;
				copyToClipBoard(res.data?.paymentLinkUrl);
			} else throw res.errors?.error;
		} catch (err) {
			// eslint-disable-next-line no-console
			const error = err ?? err?.message;
			console.log('err', err);
			if (typeof error === 'string') showToast('error', error);
			else showToast('error', 'Failed to create payment link. Please try again!');
		} finally {
			setIsSubmitting(false);
		}
	};

	const onSendLink = () => {
		const amount = amountInCaseOfRecurring.current;
		if (paymentLinkUrl)
			window.open(
				`https://wa.me/${mobile.replace(/[+-]/g, '')}?text=${encodeURIComponent(
					`Dear User,\nAs per our conversation, please find below the payment link for *₹${amount}*.\n\n${paymentLinkUrl}\n\nRequest you to make the payment and share the screenshot with us.\nTeam Yellow Class.`
				)}`,
				'_blank'
			);
	};

	const { activeProfiles, gradesToShowForCreateProfile } = useMemo(() => {
		if (formValues.isRecurring && formValues.selectedGrade) {
			return getFilteredProfileDetails(profiles, grades, Number(formValues.selectedGrade?.value) ?? 1, false);
		}
		return getFilteredProfileDetails(profiles, grades, null, false);
	}, [profiles, grades, formValues.isRecurring && formValues.selectedGrade]);

	const subscriptionBucketOptions = useMemo(() => {
		return subscriptionBuckets?.map((bucket) => {
			return {
				label: bucket.label,
				value: bucket.bucketName,
			};
		});
	}, [subscriptionBuckets]);

	useEffect(() => {
		if (activeProfiles.length === 1) {
			setValue('selectedProfile', activeProfiles[0].id);
		} else {
			setValue('selectedProfile', null);
		}
	}, [activeProfiles]);

	const createProfile = useCallback(async () => {
		const grade = getValues('selectedGrade');
		const name = getValues('profileName');
		const profile = await createOrUpdateProfileHelper(name, Number(grade?.value), userId, mobile);
		if (profile) {
			setProfiles((preFetchedProfiles) => [profile, ...preFetchedProfiles]);
			setValue('selectedGrade', '');
			setValue('selectedProfile', null);
			setValue('profileName', '');
			setValue('amount', 0);
		}
	}, []);

	const isNotEmptyActiveProfiles = isNotEmptyArray(activeProfiles);

	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<Box d="flex" ai="center" jc="space-between">
							<Box as="h3" mb="1rem">
								Create Payment Link
							</Box>
							<Box
								mb="1rem"
								cursor="pointer"
								fs="bold"
								pl="0.6rem"
								pr="0.6rem"
								pt="0.4rem"
								pb="0.4rem"
								bor="1px solid grey"
								onClick={() => closeModal()}
							>
								X
							</Box>
						</Box>

						<FormProvider {...hookFormMethods}>
							<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
								<Box w="100%" mt="1rem">
									<Row>
										<Col>
											<Checkbox name="isRecurring" label="Is this recurring payment?" disabled={isDisabled} />
										</Col>
									</Row>
									{isNotEmptyActiveProfiles && (
										<>
											<Row style={{ padding: '1rem 0rem' }}>
												<Col>
													Select subject{formValues.isRecurring ? '' : '(s)'} for below profile
													{!formValues.isRecurring && activeProfiles.length > 1 ? '(s)' : ''}
													<span style={{ color: 'red' }}> *</span>
												</Col>
											</Row>
											{activeProfiles.map((profile, index) => (
												<ProfileSubjectSelectionCards
													isGroupedByRadio={formValues.isRecurring}
													key={`${profile.id}.${index}`}
													profile={profile}
													grades={gradesToShowForCreateProfile}
													isDisabled={isDisabled}
													multiSelect={!formValues.isRecurring}
												/>
											))}
										</>
									)}
									{isNotEmptyArray(gradesToShowForCreateProfile) && (
										<div
											style={{
												padding: '1rem 1rem 0 1rem',
												width: '100%',
												borderRadius: '0.5rem',
												marginBottom: '1rem',
												backgroundColor: '#f5f5f5',
											}}
										>
											<Row>
												<Col>
													<p style={{ color: 'red' }}>* Create profile, if profile for particular grade not exists.</p>
													<SelectField
														label="Select Grade"
														name="selectedGrade"
														options={gradesToShowForCreateProfile}
														isClearable
														isDisabled={isDisabled}
													/>

													<Input label="Enter name" name="profileName" />
													<Button size="sm" color="primary" onClick={createProfile}>
														Create Profile
													</Button>
												</Col>
											</Row>
										</div>
									)}
									{isNotEmptyActiveProfiles && (
										<Box>
											{formValues.isRecurring && (
												<Row>
													<Col sm="6">
														<SelectField
															label="Select Subscription bucket"
															name="subscriptionBucketName"
															options={subscriptionBucketOptions}
															isClearable
															required
															isDisabled={isDisabled}
														/>
													</Col>
												</Row>
											)}
											<Row>
												<Col sm="6">
													<Input
														label="Send Payment Link SMS To"
														name="mobile"
														required
														helpText="Payment Link SMS will be sent to this number"
														disabled={isDisabled}
													/>
												</Col>
												<Col sm="6">
													<Input
														label="Grant Subscription To"
														name="grantSubscriptionMobile"
														disabled
														required
														helpText="Subscription will be granted to this number after payment"
													/>
												</Col>
											</Row>
											<Row>
												<Col sm="6">
													<Input label="Name" name="fullName" disabled={isDisabled} />
												</Col>
												<Col sm="6">
													<Input label="Email" name="email" type="email" disabled={isDisabled} />
												</Col>
											</Row>

											<Row>
												<Col>
													<InputNew
														type="number"
														min={1}
														max={48}
														step={1}
														label="Expiration Hrs"
														name="expireBy"
														defaultValue={48}
														disabled={isDisabled}
													/>
												</Col>
												{!formValues.isRecurring && (
													<Col sm="6">
														<Input label="Amount (Rupees)" name="amount" type="number" min={1} required disabled={isDisabled} />
													</Col>
												)}
											</Row>
											<Row>
												<Col sm="6">
													<Input
														label="Sale Attributed To"
														placeholder="Email of the sales person"
														name="createdBy"
														type="email"
														required
														disabled={isDisabled}
													/>
												</Col>
												<Col sm="6">
													<Input label="Description" name="description" type="text" disabled={isDisabled} />
												</Col>
												<Col sm="6" />
											</Row>
											<If
												condition={!paymentLinkUrl}
												then={
													<Col sm="12" className="mt-4">
														<Button size="sm" color="primary" type="submit" disabled={isSubmitting}>
															{isSubmitting ? 'Creating' : 'Create'}
														</Button>
														<Button size="sm" color="success" type="button" onClick={() => closeModal()}>
															Cancel
														</Button>
													</Col>
												}
												else={
													<Box d="flex" w="100%" ai="center">
														<Box d="flex" w="70%" ai="center">
															<div className="form__form-group">
																<span className="form__form-group-label">Payment Link URL</span>
																<div className="form__form-group-field">
																	<div className="form__form-group-input-wrap">
																		<input value={paymentLinkUrl} />
																	</div>
																</div>
															</div>
														</Box>
														<Box d="flex" flex="1" pl="4rem" mt="0.7rem" ai="center">
															<Button d="block" size="sm" color="primary" type="button" onClick={onSendLink}>
																Send link via WhatsApp
															</Button>
														</Box>
													</Box>
												}
											/>
											<Box customStyle={{ fontWeight: 'bold' }} mt="1rem" fs="bold">
												NOTE: Do not share this payment link with any other user as the subscription will only be granted to {mobile} after
												payment.
											</Box>
										</Box>
									)}
								</Box>
							</form>
						</FormProvider>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(CreatePaymentLinkModal);
