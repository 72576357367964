import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired,
		auth: PropTypes.object,
		logout: PropTypes.func,
	};

	render() {
		const { changeMobileSidebarVisibility, changeSidebarVisibility, auth, logout } = this.props;

		return (
			<div
				className="topbar"
				style={{
					backgroundColor: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? '#fdb1b1' : '#5C7BFF',
				}}
			>
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							changeSidebarVisibility={changeSidebarVisibility}
						/>
						<Link className="topbar__logo" to="/users/list">
							<span
								style={{
									color: 'white',
								}}
							>
								Speak
							</span>
							<span style={{ color: '#f55a42', fontSize: '1.7rem' }}>X</span>
						</Link>
					</div>
					{auth.isAuthenticated && (
						<div className="topbar__right">
							<TopbarProfile user={auth.user} logout={logout} />
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Topbar;
