/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import { OpenModalAction } from 'connectors/ModalController/actions';
import { ModalTypes } from 'connectors/ModalController/types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import Filter from 'reusableComponents/Filter';
import { getSanitizedImageUrl, isAllowedEmail, isNotEmptyArray, isNotEmptyObject, ObjectMaybe, showToast } from 'utils/commonHelpers';
import { dateTimeConverter } from 'utils/dateHelper';
import theme from 'utils/theme';
import { fetchWrapper } from 'utils/fetchWrapper';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { adminGrantUser, cancelSubscriptionViaAdmin, getUser, refundViaAdmin } from '../actions/user.actions';

const Actions = ({ userDetails, loggedInUser }) => {
	const { id: userId, packages, subscriptionDetails, email } = userDetails;

	const dispatch = useDispatch();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]) && (
					<DropdownItem
						onClick={() =>
							dispatch(OpenModalAction(ModalTypes.USER_SUBSCRIPTION_MODAL, { userId, packages, subscriptionDetails, loggedInUser }))
						}
					>
						Subscription Details
					</DropdownItem>
				)}
				{isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]) && (
					<DropdownItem onClick={() => dispatch(OpenModalAction(ModalTypes.GRANT_SUBSCRIPTION_MODAL, { userDetails, loggedInUser }))}>
						Grant Subscription
					</DropdownItem>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ users, loggedInUser }) {
	return users.map((userDetails) => {
		const {
			id,
			createdAt,
			profilePic,
			fullName,
			email,
			mobile,
			secondaryMobile,
			gender,
			totalReferralCount,
			processedReferralCount,
		} = userDetails;

		return {
			id,
			profilePic: ObjectMaybe(profilePic).url ? (
				<Box h="3rem" w="3rem" bRad="50%">
					<img
						width="100%"
						height="100%"
						style={{ borderRadius: '50%' }}
						src={getSanitizedImageUrl(ObjectMaybe(profilePic).url)}
						alt="profile-pic"
					/>
				</Box>
			) : (
				<Box
					d="flex"
					jc="center"
					ai="center"
					fs="2rem"
					bg={theme.clrs.cGraphite}
					c={theme.clrs.cWhite}
					h="3rem"
					w="3rem"
					bRad="50%"
					tt="capitalize"
				>
					{fullName ? fullName[0] : null}
				</Box>
			),
			fullName: fullName || '---',
			email: email && email.length ? email[0] : '---',
			mobile,
			secondaryMobile: secondaryMobile || '---',
			totalReferralCount,
			processedReferralCount,
			createdAt: dateTimeConverter(createdAt, 'format', 'dd MMM yyyy (k:mm)'),
			gender: gender || '---',
			actions: (
				<Actions
					{...{
						userDetails,
						loggedInUser,
					}}
				/>
			),
		};
	});
}

const PaymentsList = ({ userDetails, loggedInUser, dispatch }) => {
	const handleRefund = (pgOrderId) => {
		// eslint-disable-next-line no-restricted-globals
		const userConfirmed = confirm('Are you sure you want to refund this payment?');

		if (!userConfirmed) {
			return;
		}

		dispatch(
			refundViaAdmin({
				pgOrderId,
				userId: userDetails.id,
			})
		);
	};

	const isUserAllowedRefund = useMemo(() => {
		return isAllowedEmail('REACT_APP_REFUND_ADMINS', loggedInUser?.email?.[0]);
	}, []);

	return (
		<div style={{ margin: '20px' }}>
			<table
				style={{
					width: '100%',
					borderCollapse: 'collapse',
					textAlign: 'left',
				}}
			>
				<thead>
					<tr>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>PG Payment ID</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>PG Order ID</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Package</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Amount</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Currency</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Payment Status</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Created By</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{userDetails?.payments?.map((order) => (
						<tr key={order._id}>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.paymentDetails.pgPaymentId}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.pgOrderId}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order?.cart?.[0]?.packageKey}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.amount / 100}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.currency}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.paymentDetails.paymentStatus}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{order.createdBy}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
								{isUserAllowedRefund && order.paymentDetails.paymentStatus === 'SUCCESS' && order.source !== 'ADMIN_GRANT' && (
									<button
										type="button"
										onClick={() => handleRefund(order.pgOrderId)}
										style={{
											padding: '5px 10px',
											border: 'none',
											borderRadius: '4px',
											backgroundColor: '#4caf50',
											color: '#fff',
											cursor: 'pointer',
										}}
									>
										Refund
									</button>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const SubscriptionsList = ({ userDetails, loggedInUser, dispatch }) => {
	const handleCancelSubscription = (id) => {
		// eslint-disable-next-line no-restricted-globals
		const userConfirmed = confirm('Are you sure you want to cancel subscription?');

		if (!userConfirmed) {
			return;
		}

		dispatch(
			cancelSubscriptionViaAdmin({
				subscriptionId: id,
				userId: userDetails.id,
				rzpCustomerId: userDetails.rzpUserId,
			})
		);
	};

	const isUserAllowedCancelSubscription = useMemo(() => {
		return isAllowedEmail('REACT_APP_CANCEL_SUBSCRIPTION_ADMINS', loggedInUser?.email?.[0]);
	}, []);

	return (
		<div style={{ margin: '20px' }}>
			<table
				style={{
					width: '100%',
					borderCollapse: 'collapse',
					textAlign: 'left',
				}}
			>
				<thead>
					<tr>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>PG Subscription ID</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Package ID</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Recurring Amount</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Recurring Max Amount</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Cycle Count</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Payment Provider</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Subscription Status</th>
						<th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{userDetails.allSubscriptions.map((subscription) => (
						<tr key={subscription.id}>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.pgSubscriptionId}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.packageId}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.recurringAmount / 100}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.recurringMaxAmount / 100}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.paidCyclesCount}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.paymentProvider}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{subscription.subscriptionStatus}</td>
							<td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
								{isUserAllowedCancelSubscription && subscription.subscriptionStatus === 'ACTIVE' && (
									<button
										type="button"
										onClick={() => handleCancelSubscription(subscription.id)}
										style={{
											padding: '5px 10px',
											border: 'none',
											borderRadius: '4px',
											backgroundColor: '#ff4d4f',
											color: '#fff',
											cursor: 'pointer',
										}}
									>
										Cancel Subscription
									</button>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const AdminGrantPanel = ({ userDetails, loggedInUser, dispatch }) => {
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [formData, setFormData] = useState({
		duration: 0,
		adminGrantReason: '',
	});
	const [selectedPackage, setSelectedPackage] = useState('');

	const isPremiumUser = new Date(userDetails?.packageGrants?.MEMBERSHIP.validTill) > new Date();

	const fieldsToDisplay = {
		'Full Name': userDetails['fullName'],
		Gender: userDetails['gender'],
		Mobile: userDetails['mobile'],
		'User ID': userDetails['id'],
		'App Version': userDetails?.creationUserAgent?.appVersion,
		'Latest Android Version': userDetails['latestAndroidAppVersion'],
		'Premium User': isPremiumUser ? 'Yes' : 'No',
		'Valid Till': userDetails?.packageGrants?.MEMBERSHIP?.validTill
			? new Date(userDetails?.packageGrants?.MEMBERSHIP?.validTill).toLocaleString('en-IN', {
					timeZone: 'Asia/Kolkata',
					hour12: true,
			  })
			: undefined,
	};

	const isAppAtLeast500 = userDetails['latestAndroidAppVersion'] >= 500;

	if (!isAppAtLeast500) {
		return null;
	}

	const isUserAllowedAdminGrant = useMemo(() => {
		return isAllowedEmail('REACT_APP_ADMIN_GRANT_ADMINS', loggedInUser?.email?.[0]);
	}, []);

	const renderObject = (obj) => (
		<ul>
			{Object.entries(obj)
				.filter(([_, value]) => value !== undefined) // Filter out undefined values
				.map(([key, value]) => (
					<li
						key={key}
						style={{
							listStyleType: 'none',
							margin: '0.5rem 0',
						}}
					>
						<strong>{key}:</strong>{' '}
						{typeof value === 'object' && value !== null ? (
							Array.isArray(value) ? (
								<ul>
									{value.map((item, index) => (
										<li key={index}>{renderObject(item)}</li>
									))}
								</ul>
							) : (
								renderObject(value)
							)
						) : (
							String(value)
						)}
					</li>
				))}
		</ul>
	);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	return (
		<>
			<Row>
				<Col md={6} lg={6}>
					<h4>User Details</h4>
				</Col>
			</Row>
			<Row>
				<Col md={6} lg={6}>
					<div>{renderObject(fieldsToDisplay)}</div>
				</Col>
				<Col md={6} lg={6}>
					{userDetails?.customUserPic && (
						<div
							style={{
								width: '150px',
								height: '150px',
							}}
						>
							<img
								style={{ borderRadius: '150px', border: '2px solid #eee' }}
								src={getSanitizedImageUrl(userDetails?.customUserPic)}
								alt="profile-pic"
							/>
						</div>
					)}
				</Col>
			</Row>
			<br />
			<br />
			<Row>
				<Col md={6} lg={6}>
					{isUserAllowedAdminGrant && (
						<>
							{/* admin grant user */}
							<div style={{ position: 'relative', display: 'inline-block', width: '90%' }}>
								{isFormOpen ? (
									<div
										style={{
											position: 'absolute',
											top: 5,
											right: 5,
											cursor: 'pointer',
											color: '#001b7d',
										}}
										onClick={() => {
											setIsFormOpen(!isFormOpen);
										}}
									>
										ㄨ
									</div>
								) : (
									<Button
										type="button"
										onClick={() => {
											setIsFormOpen(!isFormOpen);
										}}
										style={{ marginBottom: '10px' }}
									>
										Admin Grant
									</Button>
								)}

								{isFormOpen && (
									<div
										style={{
											backgroundColor: '#dde1f0',
											padding: '1rem',
											borderRadius: '5px',
										}}
									>
										<h2>Admin Grant</h2>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												const cartPackage = userDetails.dbPackages.find((pkg) => pkg.key === selectedPackage);
												dispatch(
													adminGrantUser({
														...formData,
														userId: userDetails.id,
														loggedInUserMail: loggedInUser?.email?.[0],
														duration: Number(formData.duration),
														cart: {
															packageId: cartPackage._id,
															packageKey: cartPackage.key,
															packageType: cartPackage.type,
															duration: Number(formData.duration),
														},
													})
												);
											}}
										>
											<div style={{ marginBottom: '10px' }}>
												<label htmlFor="duration" style={{ display: 'block', marginBottom: '5px' }}>
													Duration
												</label>
												<input
													required
													type="number"
													id="duration"
													name="duration"
													value={formData.duration}
													onChange={handleChange}
													placeholder="Enter duration"
													style={{
														width: '100%',
														padding: '8px',
														border: '1px solid #ccc',
														borderRadius: '4px',
													}}
												/>
											</div>
											<div style={{ marginBottom: '10px' }}>
												<label htmlFor="adminGrantReason" style={{ display: 'block', marginBottom: '5px' }}>
													Admin Grant Reason
												</label>
												<textarea
													required
													id="adminGrantReason"
													name="adminGrantReason"
													value={formData.adminGrantReason}
													onChange={handleChange}
													placeholder="Enter reason"
													style={{
														width: '100%',
														padding: '8px',
														border: '1px solid #ccc',
														borderRadius: '4px',
													}}
												/>
											</div>
											<div style={{ marginBottom: '10px' }}>
												<label htmlFor="packageSelect" style={{ display: 'block', marginBottom: '5px' }}>
													Select a Package
												</label>
												<select
													required
													id="packageSelect"
													value={selectedPackage}
													onChange={(e) => setSelectedPackage(e.target.value)}
													style={{
														width: '100%',
														padding: '8px',
														border: '1px solid #ccc',
														borderRadius: '4px',
													}}
												>
													<option value="">-- Select a Package --</option>
													{userDetails.dbPackages.map((pkg) => (
														<option key={pkg._id} value={pkg.key}>
															{pkg.key}
														</option>
													))}
												</select>
											</div>
											<Button type="submit" color="primary">
												Grant
											</Button>
										</form>
									</div>
								)}
							</div>
						</>
					)}
				</Col>
				{/* <Col md={6} lg={6}>
					{isPremiumUser && (
						<>
							<Button
								type="button"
								onClick={() => {
									console.log('REVOKE BENEFIT');
								}}
								style={{ marginBottom: '10px' }}
							>
								Revoke
							</Button>
						</>
					)}
				</Col> */}
			</Row>
			<br />
			<br />
			{userDetails?.allSubscriptions?.length > 0 && (
				<Row>
					<Col md={12} lg={12}>
						<h4>Subscription Details</h4>
						<SubscriptionsList userDetails={userDetails} loggedInUser={loggedInUser} dispatch={dispatch} />
					</Col>
				</Row>
			)}
			{userDetails?.payments?.length > 0 && (
				<Row>
					<Col md={12} lg={12}>
						<h4>Payment Details</h4>
						<PaymentsList userDetails={userDetails} loggedInUser={loggedInUser} dispatch={dispatch} />
					</Col>
				</Row>
			)}
		</>
	);
};

export const UserTable = () => {
	const userState = useSelector(({ user }) => ({
		loading: user.loading,
		users: user.users,
		pageNo: user.pageNo,
		total: user.total,
		apiParams: user.apiParams,
	}));

	const { loggedInUser, userDetails, isLoading } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		userDetails: state.user.userDetails,
		isLoading: state.user.loading,
	}));

	const dispatch = useDispatch();

	const [userRows, setUserRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState<string>(userState.apiParams.query);

	const heads = [
		{ accessor: 'profilePic', Header: 'Profile Picture' },
		{ accessor: 'fullName', Header: 'Full Name' },
		{ accessor: 'mobile', Header: 'Mobile No.' },
		{ accessor: 'createdAt', Header: 'Created At' },
		{ accessor: 'gender', Header: 'Gender' },
		{ accessor: 'actions', Header: 'Actions' },
	];

	React.useEffect(() => {
		setPageOfItems(userState.pageNo);
	}, [userState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(userState.users)) {
			const structuredRows = createRows({ users: userState.users, loggedInUser });
			setUserRows(structuredRows);
		} else {
			setUserRows([]);
		}
	}, [userState.users]);

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		if (!searchTerm) {
			showToast('error', 'Please enter the valid mobile number');
		} else {
			dispatch(
				getUser({
					searchTerm: searchTerm.length > 10 && !searchTerm.startsWith('+91-') ? searchTerm : `+91-${searchTerm}`,
				})
			);
		}
	};

	const [selectedNumber, setSelectedNumber] = useState('1');
	const [selectedUserType, setSelectedUserType] = useState('prePurchase');
	const [apiCallUnderProgress, setApiCallUnderProgress] = useState(false);
	const [matchingUserResult, setMatchingUserResult] = useState([]);

	return (
		<>
			<Col md={12} lg={12}>
				<Card>
					<LoadingOverlay isLoading={isLoading} />
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={12}>
								<h3 className="mb-4">User Search Panel</h3>
							</Col>
							<Col sm={6}>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by Mobile (or) user ID"
								/>
							</Col>
						</Row>

						{isNotEmptyObject(userDetails) && <AdminGrantPanel userDetails={userDetails} loggedInUser={loggedInUser} dispatch={dispatch} />}
					</CardBody>
					{/* TESTING MOBILE NUMBERS SEARCH CODE */}
					<CardBody>
						<h3 className="mb-4">Test numbers search</h3>
						<Col sm={12}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'center',
									margin: '0.5rem 0',
									flexWrap: 'wrap',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '30%',
									}}
								>
									<p
										style={{
											padding: '0 1rem 0 0',
										}}
									>
										Select bucket character:{' '}
									</p>
									<select
										id="bucketCharacter"
										value={selectedNumber}
										onChange={(e) => {
											setSelectedNumber(e.target.value);
										}}
										style={{
											padding: '0.5rem',
										}}
									>
										{'0123456789abcdef'.split('').map((op, index) => (
											<option key={op} value={op}>
												{op}
											</option>
										))}
									</select>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '30%',
									}}
								>
									<p
										style={{
											padding: '0 1rem 0 0',
										}}
									>
										Select user type:{' '}
									</p>
									<select
										id="typeOfUser"
										value={selectedUserType}
										onChange={(e) => {
											setSelectedUserType(e.target.value);
										}}
										style={{
											padding: '0.5rem',
										}}
									>
										<option value="prePurchase">Pre Purchase</option>
										<option value="postPurchase">Post Purchase</option>
										<option value="expired">Expired</option>
									</select>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '100%',
									}}
								>
									<Button
										disabled={apiCallUnderProgress}
										size="sm"
										color="primary"
										type="submit"
										onClick={async () => {
											setMatchingUserResult([]);
											setApiCallUnderProgress(true);

											let res = [];
											try {
												res = (
													await fetchWrapper('/admin/get-ideal-user', {
														method: 'POST',
														body: {
															bucketKey: selectedNumber,
															prePurchase: selectedUserType === 'prePurchase',
															postPurchase: selectedUserType === 'postPurchase',
														},
													})
												)?.data;
												if (res.length > 0) {
													showToast('success', 'Matching users found!');
												} else {
													showToast('info', 'No matching users found!');
												}
											} catch (e) {
												showToast('error', 'Something went wrong!');
											}

											setMatchingUserResult(res);
											setApiCallUnderProgress(false);
										}}
										style={{
											marginTop: '1rem',
										}}
									>
										Search
									</Button>
								</div>

								{apiCallUnderProgress && (
									<div>
										<img width="200" height="200" src={getSanitizedImageUrl('assets/loader/catLoader2.gif')} alt="loader" />

										<p>Please wait...</p>
									</div>
								)}
							</div>
							{matchingUserResult.length > 0 &&
								matchingUserResult.map((user) => {
									return (
										<div
											key={user.id}
											style={{
												backgroundColor:
													selectedUserType === 'prePurchase'
														? '#ff939345'
														: selectedUserType === 'postPurchase'
														? '#bdffbd7d'
														: '#fdff754f',
												border: '1px solid black',
												borderColor:
													selectedUserType === 'prePurchase' ? '#f17373' : selectedUserType === 'postPurchase' ? '#21de21' : '#d8de21',
												padding: '0.5rem',
												marginTop: '1rem',
												borderRadius: '5px',
												width: '50vw',
											}}
										>
											<p>Mobile : {user.mobile}</p>
											<p>Profile Id : {user?.activeProfileId}</p>
											<p>User Id : {user._id}</p>
											{user?.subscriptionData?.recurringAmount ? (
												<p>Subscription Amount : ₹ {user?.subscriptionData?.recurringAmount / 100}</p>
											) : (
												<></>
											)}
											<p>
												Created At : {new Date(user.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', hour12: true })} IST |{' '}
												{new Date(user.createdAt).toLocaleString('en-GB', { timeZone: 'GMT', hour12: true })} GMT
											</p>
											{/* <p>Created At GMT: {new Date(user.createdAt).toLocaleString('en-GB', { timeZone: 'GMT', hour12: true })}</p> */}
										</div>
									);
								})}
						</Col>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
