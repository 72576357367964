import queryString from 'query-string';
import { filter, path, pick, omit } from 'ramda';
import reduce from 'lodash/reduce';
import { toast } from 'react-toastify';

import { IMAGE_URL } from './constants';

export const actionSpreader = (type: string, payload?: any, origin?: any) => ({ type, payload, origin });
export const ArrayMaybe = <T extends any[]>(arr: T) => arr || ([] as T);
export const ObjectMaybe = <T extends Record<string, any>>(obj: T) => obj || ({} as T);
export const StringMaybe = (str) => str || '';
export const renameObjectKeys = (obj, newKeysmap) =>
	reduce(
		obj,
		(result, value, key) => {
			key = newKeysmap[key] || key;
			result[key] = value;
			return result;
		},
		{}
	);
export const pickWrapper = (keys, object) => pick(keys, object);
export const omitWrapper = (keys, object) => omit(keys, object);
export const isNotEmptyArray = (x) => x && x.length > 0;
export const isNotEmptyObject = (obj) => !!obj && Object.keys(obj).length > 0 && Object.values(obj).some((x) => x !== null && x !== '');
export const removeNonTrueValuesFromObject = (obj) => filter(Boolean, obj);
export const accessDeepObject = (arr, obj) => path(Array.isArray(arr) ? arr : arr.split('.'), obj);
export const startsWithDigit = (text: string) => text.match(/^\d/);

export const stringifyQueryParams = (qp) => `${isNotEmptyObject(qp) ? `?${queryString.stringify(qp)}` : ''}`;
export const parseQueryParams = (qp) => (qp ? (queryString.parse(qp) as any) : {}) as Record<string, string>;

export const capitalizeString = (str) => str[0].toUpperCase() + str.slice(1).toLowerCase();

export const convertEnumToArray = (arr) =>
	arr.map((item) => ({
		value: item.name,
		label: capitalizeString(item.name).replace(/_/g, ' '),
	}));

export const convertStringToArrayObjects = (arr) =>
	arr.map((item) => ({
		value: item,
		label: capitalizeString(item).replace(/_/g, ' '),
	}));

export const getSelectFieldValue = (obj) => obj.value;

export const setSelectFieldValue = (val, arr) => arr.find((x) => x.value === val);

export const numberRange = (start: number, end: number) =>
	Array(end - start + 1)
		.fill(undefined)
		.map((_, idx) => start + idx);

export const boolToInt = (res) => (res ? 1 : 0);
export const intToBool = (res) => !!res;

export const removeNestedKey = (obj, propToDelete) => {
	// eslint-disable-next-line no-restricted-syntax
	for (const property in obj) {
		if (typeof obj[property] === 'object') {
			delete obj.property;
			const newJsonData = removeNestedKey(obj[property], propToDelete);
			obj[property] = newJsonData;
		} else if (property === propToDelete) {
			delete obj[property];
		}
	}
	return obj;
};

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// eslint-disable-next-line no-mixed-operators
export const genRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const cleanAnalyticsText = (str) => StringMaybe(str).replace(/ /g, '_').toLowerCase();

/**
 *
 * @param {Object} queryParams
 * @returns pageUrl with all the queryParams
 */
export const getPageUrlWithQueryParams = (baseUrl, queryParams) =>
	`${baseUrl}?${Object.entries(queryParams)
		.map(([queryName, queryVal]) => `&${queryName}=${queryVal}`)
		.join('')
		.slice(1)}`;

export const parseAddress = (results) => {
	const d = ObjectMaybe(ArrayMaybe(results)[0]).address_components;
	let locality = ObjectMaybe(ArrayMaybe(d).find((x) => x.types.includes('sublocality_level_1'))).long_name || null;
	if (!locality) {
		locality = ObjectMaybe(ArrayMaybe(d).find((x) => x.types.includes('locality'))).long_name || null;
	}
	let city = ObjectMaybe(ArrayMaybe(d).find((x) => x.types.includes('administrative_area_level_2'))).long_name || null;
	if (!city) {
		city = ObjectMaybe(ArrayMaybe(d).find((x) => x.types.includes('administrative_area_level_1'))).long_name || null;
	}
	return {
		locality: {
			name: locality,
		},
		city: {
			name: city,
		},
	};
};

export const getSanitizedImageUrl = (relativePath = '', imgixQP = {}): string => {
	if (!relativePath) return null;

	const containsLeadingSlash = relativePath.startsWith('/');
	const imgixPrefix = containsLeadingSlash ? IMAGE_URL : `${IMAGE_URL}/`;

	const relativePathParts = relativePath.split('?');

	// CODE: done intensionaly as imagic misbehaves on svg images
	const isSvg = relativePathParts[0].endsWith('.svg');
	if (isSvg) {
		return getCFSanitizedImageUrl(relativePath);
	}

	const existingQp = relativePathParts[1];
	const modifiedImgixQP = {
		...queryString.parse(existingQp),
		...imgixQP,
	};

	const outputRelativePath = relativePathParts[0].endsWith('/') ? relativePathParts[0].slice(0, -1) : relativePathParts[0];

	return relativePath.match(/^https?/)
		? `${outputRelativePath}${stringifyQueryParams(modifiedImgixQP)}`
		: `${imgixPrefix}${outputRelativePath}${stringifyQueryParams(modifiedImgixQP)}`;
};

export const getCFSanitizedImageUrl = (relativePath = '') => {
	if (!relativePath) return '';

	const containsLeadingSlash = relativePath.startsWith('/');
	const imgixPrefix = containsLeadingSlash
		? process.env.REACT_APP_CLOUDFRONT_STATIC_PREFIX
		: `${process.env.REACT_APP_CLOUDFRONT_STATIC_PREFIX}/`;

	return relativePath.match(/^https?/) ? relativePath : `${imgixPrefix}${relativePath}`;
};

export const getSanitizedAudioUrl = (relativePath = '') => {
	const prefix = process.env.REACT_APP_CLOUDFRONT_AUDIO_PREFIX;
	const outputRelativePath = relativePath[0].startsWith('/') ? relativePath : `/${relativePath}`;

	return relativePath.match(/^https?/) ? relativePath : `${prefix}${outputRelativePath}`;
};

export const getSanitizedVideoUrl = (relativePath = '') => {
	const prefix = process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX;
	const outputRelativePath = relativePath[0].startsWith('/') ? relativePath : `/${relativePath}`;

	return relativePath.match(/^https?/) ? relativePath : `${prefix}${outputRelativePath}`;
};

export const getVideoThumbnailUrl = () => `assets/video_thumbnail/thumb_${Math.ceil(Math.random() * 5)}.jpg`;

export const getCountryFlagUrl = (code) => `https://www.countryflags.io/${code.toUpperCase()}/flat/32.png`;

export const isProviderImage = (url) => url && (url.includes('graph.facebook.com') || url.includes('googleusercontent'));

export const copyToClipBoard = (text) => {
	const dummy = document.createElement('textarea');
	document.body.appendChild(dummy);
	dummy.value = text;
	dummy.select();
	document.execCommand('copy');
	document.body.removeChild(dummy);
};

export const stringToSlug = (str) =>
	str
		.toLowerCase()
		.replace(/[^a-z0-9 -]/g, '')
		.replace(/^\s+|\s+$/g, '')
		.replace(/\s+/g, '-')
		.replace(/-+/g, '-');

export const showToast = (type, msg, autoClose = 5000) => {
	switch (type) {
		case 'success':
			toast.success(msg, { autoClose });
			break;
		case 'error':
			toast.error(msg, { autoClose });
			break;
		case 'info':
			toast.info(msg, { autoClose });
			break;
		case 'warn':
			toast.warn(msg, { autoClose });
			break;
		default:
			break;
	}
};

export const modifyBeforeSetTags = (data) => {
	return data.map((opt) => ({ label: opt.name, value: opt.name }));
};

export const getSanitizedFbProfileLink = (link) => {
	const d = link.split('?');
	const qp = parseQueryParams(d[1]);
	if (link.includes('profile.php')) {
		const dd = pickWrapper(['id'], qp);
		return `${d[0]}${stringifyQueryParams(dd)}`;
	}
	return d[0];
};

export const checkRequiredEntitiesPresence = (params, entities) => {
	let notPresentEntities = [];
	entities.forEach((entity) => {
		if (!params[entity]) {
			notPresentEntities = [...notPresentEntities, entity];
		}
	});
	return notPresentEntities;
};

export const getVideoIdFromUrl = (url) => {
	const urlArray = url.split('/');
	return urlArray[urlArray.length - 1];
};

/**
 * Wait amount ms before executing next line
 * @param {Number} amount number of ms to wait
 */
export const wait = (amount = 0) => new Promise((resolve) => setTimeout(resolve, amount));

/**
 * Returns if the admin has permission for specific operation or not
 * @param {String} envKey env variable for the allowed list of emails
 * @param {String} email logged in admin email
 */
export const isAllowedEmail = (envKey, email) => {
	const allowedEmails = StringMaybe(process.env[envKey]).split(' ');
	return allowedEmails.includes(email);
};
export const isValidJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};
