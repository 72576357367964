import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';

import { fetchWrapper } from 'utils/fetchWrapper';
import { isValidJson } from 'utils/commonHelpers';
import Box from '../../../reusableComponents/Box';

const RedisCli = () => {
	const [originalCliDataType, setOriginalCliDataType] = useState(null);
	const [cliDataType, setCliDataType] = useState(null);
	const [error, setError] = useState(null);
	const [cliData, setCliData] = useState(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, setValue, ...hookFormMethods } = useForm();

	const onSubmit = async (values) => {
		setOriginalCliDataType(null);
		setCliDataType(null);
		setCliData(null);
		setError(null);
		const { command } = values;
		if (!command) {
			return null;
		}
		if (command.toLowerCase().includes('keys') || command.toLowerCase().includes('flushall') || command.toLowerCase().includes('del')) {
			// eslint-disable-next-line no-alert, no-restricted-globals
			const x = confirm(`Are you sure you want to run "${command}"?`);
			if (!x) {
				return null;
			}
		}
		setLoading(true);
		const res = await fetchWrapper('/v1/admin/redis-cli', {
			method: 'POST',
			body: values,
		});

		if (res.status !== 200) {
			// console.log('status !== 200', res.status);
			setError(res);
			setLoading(false);
		} else {
			setLoading(false);
			// FORMAT 1
			// setCliDataType(res.data.dataType);
			// setCliData(res.data.response);

			// FORMAT 2
			setOriginalCliDataType(res.data.dataType);
			let dataType;
			if (res.data.dataType === 'object' && Array.isArray(res.data.response)) {
				dataType = 'array';
			} else if (res.data.dataType === 'object' && !Array.isArray(res.data.response)) {
				dataType = 'json';
			} else if (res.data.dataType === 'string' && isValidJson(res.data.response)) {
				dataType = 'json';
			} else {
				dataType = res.data.dataType;
			}

			let finalRes;
			if (dataType === 'json') {
				finalRes = JSON.stringify(res.data.response);
			} else if (dataType === 'array') {
				finalRes = res.data.response.map((x) => <div>{x}</div>);
			} else {
				finalRes = res.data.response;
			}

			// console.log('res', dataType, finalRes);
			setCliDataType(dataType);
			setCliData(finalRes);
		}
	};

	return (
		<LoadingOverlay isLoading={loading} msg="Fetching Data from Redis...">
			<FormProvider {...{ register, handleSubmit, errors, setValue, ...hookFormMethods }}>
				<div>
					<Card>
						<CardBody>
							<Box w="100%">
								<Row>
									<Col sm="12">
										<h3>Redis CLI</h3>
									</Col>
									<Col sm="12" style={{ paddingTop: '10px' }}>
										<h5>Write the redis command in the input field, press Submit and see the response below</h5>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
									<Col sm="12">
										<Input label="Redis Command" name="command" />
									</Col>
									<Col sm="12">
										<Button size="sm" color="primary" type="submit">
											Submit
										</Button>
									</Col>
								</form>
							</Box>
							<Box>
								<Col sm="12">
									<h4 style={{ paddingBottom: '10px' }}>Command Output</h4>
									{/* FORMAT 1 */}
									{/* <div>Data Type of Key: {cliDataType}</div>
								<code>{cliData}</code> */}

									{/* FORMAT 2 */}
									{error && <code>{JSON.stringify(error)}</code>}
									{originalCliDataType && <div>Original Data Type of Key: {originalCliDataType}</div>}
									{cliDataType && <div style={{ paddingBottom: '10px' }}>Parsed Data Type of Key: {cliDataType}</div>}
									{cliData && <code>{cliData}</code>}
								</Col>
							</Box>
						</CardBody>
					</Card>
				</div>
			</FormProvider>
		</LoadingOverlay>
	);
};

export default RedisCli;
