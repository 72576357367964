import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { grantUserSubscription } from './actions/user.actions';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		users: [],
		admins: [],
		isSubmitting: false,
		userDetails: {},
		pageNo: 1,
		certificatePageNo: 1,
		total: 0,
		certificateTotal: 0,
		loading: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit !== undefined && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && action.payload.skip !== null && { skip: action.payload.skip }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
			};
		},
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setUserDetails: (state, action) => {
			state.userDetails = action.payload;
		},
	},
	extraReducers: {
		[grantUserSubscription.fulfilled as any]: (state, action) => {
			if (!action.payload) return state;
			const {
				payload: { userId, premiumValidTill },
			} = action;
			state.users.find((user) => user.id === userId).premiumValidTill = premiumValidTill;
		},
	},
});

export const { setApiParams, toggleLoading, toggleIsSubmitting, setUserDetails } = userSlice.actions;

export default userSlice.reducer;
