/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import './style/AudioPlayer.css';
import { getCFSanitizedImageUrl, getSanitizedAudioUrl, isNotEmptyObject } from 'utils/commonHelpers';
import { te } from 'date-fns/locale';

interface AudioData {
	audioUrls: {
		text: string;
		audioUrl: string;
	}[];
}

interface AudioPlayerProps {
	audioData: AudioData;
	text: string;
}

const capitalizeFirstLetter = (text) => {
	if (!text) return '';
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioData, text }) => {
	if (!isNotEmptyObject(audioData)) return <></>;

	const [playingIndex, setPlayingIndex] = useState<number | null>(null);
	const audioRefs = useRef<HTMLAudioElement[]>([]);

	const handlePlayPause = (index: number) => {
		if (playingIndex === index) {
			audioRefs.current[index]?.pause();
			setPlayingIndex(null);
		} else {
			audioRefs.current.forEach((audio, i) => {
				if (i !== index) audio?.pause();
			});
			audioRefs.current[index]?.play();
			setPlayingIndex(index);
		}
	};

	return (
		<div className="audio-player-container">
			{audioData.audioUrls.map((audio, index) => {
				if (audio?.audioUrl) {
					return (
						<>
							<div key={index} className="audio-item">
								<img
									src={
										playingIndex === index
											? getCFSanitizedImageUrl('/assets/admin/audioMicrophonePausedGreyed.png')
											: getCFSanitizedImageUrl('/assets/admin/audioMicrophonePlaying.gif')
									}
									alt={playingIndex === index ? 'Pause' : 'Play'}
									className="audio-icon"
									onClick={() => handlePlayPause(index)}
								/>
								<audio
									ref={(el) => {
										if (el) audioRefs.current[index] = el;
									}}
									src={getSanitizedAudioUrl(audio.audioUrl)}
									onEnded={() => setPlayingIndex(null)}
								/>
							</div>
							<div className="audio-item audio-item-text">{capitalizeFirstLetter(text)}</div>
						</>
					);
				}

				return <></>;
			})}
		</div>
	);
};

export default AudioPlayer;
