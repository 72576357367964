import { GPTModels } from 'containers/Lessons/utils/types';

export const STATUS_DELETED = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_UNVERIFIED = 2;
export const STATUS_VERIFIED = 3;
export const STATUS_BLOCKED = 4;
export const STATUS_INACTIVE = 5;
export const STATUS_INPROGRESS = 6;
export const STATUS_REJECTED = 7;
export const STATUS_NOT_APPLICABLE = 8;

export const STATUS_ENTITY_DRAFT = 701;
export const STATUS_ENTITY_PUBLISHED = 702;
export const STATUS_ENTITY_UNPUBLISHED = 703;

export const STATUS_SESSION_SCHEDULED = 401;
export const STATUS_SESSION_IN_PROGRESS = 402;
export const STATUS_SESSION_PAST = 403;

export const STATUS_CAREER_ACTIVE = 601;
export const STATUS_CAREER_INACTIVE = 602;

export const STATUS_SUCCESS_STORY_ACTIVE = 101;
export const STATUS_SUCCESS_STORY_INACTIVE = 102;

export const STATUS_EXPERIMENT_ENABLED = 651;
export const STATUS_EXPERIMENT_DISABLED = 652;
export const ResponseStatus = {
	OK: 200,
	NODATA: 204,
	RESETCONTENT: 205,
	BADREQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	INVALIDPARAM: 406,
	CONFLICT: 409,
	UNSUPPORTED_MEDIA: 415,
	INVALID_TOKEN: 498,
	TOKEN_REQUIRED: 499,
	SERVER_ERROR: 500,
};

export const errorCodes = {
	SERVER_ERROR: 'Server issue. Please try again',
	WRONG_CREDENTIALS: 'Username or Password is incorrect, Please try again.',
};

export const IMAGE_URL = process.env.REACT_APP_IMGIX_PREFIX;
export const VIDEO_URL = process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX;
export const WEBSITE_DOMAIN = 'yellowclass.com';
export const WEBSITE_URL = 'https://www.ivykids.in';
export const MEETING_URL = 'https://www.ivykids.in';
export const ADMIN_WEBSITE_URL = 'https://admin.yellowclass.com';

export const monthsList = [
	{ value: 0, label: 'January', shortLabel: 'Jan' },
	{ value: 1, label: 'Feburary', shortLabel: 'Feb' },
	{ value: 2, label: 'March', shortLabel: 'Mar' },
	{ value: 3, label: 'April', shortLabel: 'Apr' },
	{ value: 4, label: 'May', shortLabel: 'May' },
	{ value: 5, label: 'June', shortLabel: 'Jun' },
	{ value: 6, label: 'July', shortLabel: 'Jul' },
	{ value: 7, label: 'August', shortLabel: 'Aug' },
	{ value: 8, label: 'September', shortLabel: 'Sep' },
	{ value: 9, label: 'October', shortLabel: 'Oct' },
	{ value: 10, label: 'November', shortLabel: 'Nov' },
	{ value: 11, label: 'December', shortLabel: 'Dec' },
];

export const statusList = [
	{ label: 'Active', value: STATUS_ACTIVE },
	{ label: 'Inactive', value: STATUS_INACTIVE },
];

export const hlsStreamStatusValues = {
	1: 'S3_UPLOAD_START',
	2: 'S3_UPLOAD_ERROR',
	3: 'CONVERT_START',
	4: 'CONVERT_COMPLETE',
	5: 'CONVERT_ERROR',
};

export const getCloudFrontVideoPrefix = () => {
	return process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX;
};

export const getCloudFrontStaticPrefix = () => {
	return process.env.REACT_APP_CLOUDFRONT_STATIC_PREFIX;
};

export const ageRangeOptions = [
	{ label: 'less than 1 year', value: 0 },
	{ label: '1 year', value: 1 },
	{ label: '2 years', value: 2 },
	{ label: '3 years', value: 3 },
	{ label: '4 years', value: 4 },
	{ label: '5 years', value: 5 },
	{ label: '6 years', value: 6 },
	{ label: '7 years', value: 7 },
	{ label: '8 years', value: 8 },
	{ label: '9 years', value: 9 },
	{ label: '10 years', value: 10 },
	{ label: '11 years', value: 11 },
	{ label: '12 years', value: 12 },
	{ label: '13 years', value: 13 },
	{ label: '14 years', value: 14 },
	{ label: '15 years', value: 15 },
	{ label: '16 years', value: 16 },
];

export const languageList = [
	{ label: 'Hindi', value: 'HINDI' },
	{ label: 'English', value: 'ENGLISH' },
];

export const textStyleOptions = [
	{ label: 'H1_700', value: 'H1_700' },
	{ label: 'H1_600', value: 'H1_600' },
	{ label: 'H1_400', value: 'H1_400' },
	{ label: 'H2_700', value: 'H2_700' },
	{ label: 'H2_600', value: 'H2_600' },
	{ label: 'H2_400', value: 'H2_400' },
	{ label: 'H3_700', value: 'H3_700' },
	{ label: 'H3_600', value: 'H3_600' },
	{ label: 'H3_400', value: 'H3_400' },
	{ label: 'H4_700', value: 'H4_700' },
	{ label: 'H4_600', value: 'H4_600' },
	{ label: 'H4_400', value: 'H4_400' },
	{ label: 'H5_700', value: 'H5_700' },
	{ label: 'H5_600', value: 'H5_600' },
	{ label: 'H5_400', value: 'H5_400' },
	{ label: 'B1_700', value: 'B1_700' },
	{ label: 'B1_600', value: 'B1_600' },
	{ label: 'B1_400', value: 'B1_400' },
	{ label: 'B2_700', value: 'B2_700' },
	{ label: 'B2_600', value: 'B2_600' },
	{ label: 'B2_400', value: 'B2_400' },
	{ label: 'B3_700', value: 'B3_700' },
	{ label: 'B3_600', value: 'B3_600' },
	{ label: 'B3_400', value: 'B3_400' },
	{ label: 'B4_700', value: 'B4_700' },
	{ label: 'B4_600', value: 'B4_600' },
	{ label: 'B4_400', value: 'B4_400' },
	{ label: 'RH3_700', value: 'RH3_700' },
];

export const textAlignOptions = [
	{ label: 'CENTER', value: 'CENTER' },
	{ label: 'LEFT', value: 'LEFT' },
	{ label: 'RIGHT', value: 'RIGHT' },
];

export const flexAlignmentOptions = [
	{ label: 'FLEX_START', value: 'FLEX_START' },
	{ label: 'FLEX_END', value: 'FLEX_END' },
	{ label: 'CENTER', value: 'CENTER' },
	{ label: 'SPACE_BETWEEN', value: 'SPACE_BETWEEN' },
	{ label: 'SPACE_AROUND', value: 'SPACE_AROUND' },
	{ label: 'SPACE_EVENLY', value: 'SPACE_EVENLY' },
];

export const bottomStripAlignmentOptions = [
	{ label: 'LEFT', value: 'CENTER_LEFT' },
	{ label: 'RIGHT', value: 'CENTER_RIGHT' },
	{ label: 'CENTER', value: 'CENTRE' },
];

export const CLICK_ACTION_OPTIONS = {
	EXTERNAL_LINK: 'EXTERNAL_LINK',
	APP_LINK: 'APP_LINK',
	WEBVIEW: 'WEBVIEW',
	DISABLED: 'DISABLED',
	APP_CUSTOM: 'APP_CUSTOM',
};

export const clickActionOptions = [
	{ label: 'External Link', value: CLICK_ACTION_OPTIONS.EXTERNAL_LINK },
	{ label: 'App Link', value: CLICK_ACTION_OPTIONS.APP_LINK },
	{ label: 'Webview', value: CLICK_ACTION_OPTIONS.WEBVIEW },
	{ label: 'Disabled', value: CLICK_ACTION_OPTIONS.DISABLED },
];

export enum VoiceModelNameEnum {
	NEERJA = 'NEERJA',
	PRABHAT = 'PRABHAT',
	ANANYA = 'ANANYA',
	AASHI = 'AASHI',
	KUNAL = 'KUNAL',
	AARAV = 'AARAV',
	KAVYA = 'KAVYA',
	IVY = 'IVY',
	JUSTIN = 'JUSTIN',
}

export const roleVoiceOptions = [
	{ label: 'Neerja (Female)', value: VoiceModelNameEnum.NEERJA },
	{ label: 'Prabhat (Male)', value: VoiceModelNameEnum.PRABHAT },
	{ label: 'Ananya (Female)', value: VoiceModelNameEnum.ANANYA },
	{ label: 'Aashi (Female)', value: VoiceModelNameEnum.AASHI },
	{ label: 'Kunal (Male)', value: VoiceModelNameEnum.KUNAL },
	{ label: 'Aarav (Male)', value: VoiceModelNameEnum.AARAV },
	{ label: 'Kavya (Female)', value: VoiceModelNameEnum.KAVYA },
	{ label: 'Ivy (Female Kid)', value: VoiceModelNameEnum.IVY },
	{ label: 'Justin (Male Kid)', value: VoiceModelNameEnum.JUSTIN },
];

export const getPhoneNoRegex = ({ isHyphenMandatory = false }: { isHyphenMandatory?: boolean } = {}) =>
	new RegExp(`^\\+([0-9]{2})-${isHyphenMandatory ? '' : '?'}([0-9]{10})$`);
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const gptModelOptions = [
	{ label: 'GPT-4O', value: GPTModels.GPT_4O },
	{ label: 'GPT-4O MINI', value: GPTModels.GPT_4O_MINI },
	{ label: 'GPT-4', value: GPTModels.GPT_4 },
	{ label: 'GPT-3.5 TURBO', value: GPTModels.GPT_3_5_TURBO },
];
