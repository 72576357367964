export type TCurriculum = {
	id: string;
	title: string;
};

export enum UiTypeEnum {
	VOCAB = 'VOCAB',
	GRAMMAR = 'GRAMMAR',
	READING = 'READING',
	ROLEPLAY = 'ROLEPLAY',
	CALL = 'CALL',
	SPEAK = 'SPEAK',
	GRAMMAR_GAME = 'GRAMMAR_GAME',
	CROSSWORD_GAME = 'CROSSWORD_GAME',
	CHALLENGE_GAME = 'CHALLENGE_GAME',
}

export type TAudioData = {
	title?: [TVoiceAudio];
	completeSentence?: [TVoiceAudio];
	correctSentence?: [TVoiceAudio];
	meaning?: [TVoiceAudio];
	scene?: [TVoiceAudio];
	firstBotDialogue?: [TVoiceAudio];
	options?: Array<[TVoiceAudio]>;
};

type TSubtopic = {
	id: string;
	title?: TLanguage;
	adminCode: string;
	status: ContentDocumentStatusEnum;
	rankDecimal?: number;
	rankNumber?: number;
	description?: TLanguage;
	createdBy?: string;
	updatedBy?: string;
};

type TTextAndAudioUrl = {
	text?: string;
	variableText?: string;
	audioUrl?: string;
};

type TVoiceAudio = {
	voiceId: string;
	audioUrl: [TTextAndAudioUrl];
};

type TFragmentedTextAndAudioUrl = {
	text: string;
	audio?: [TVoiceAudio];
};

type TMultilingualTextAndAudioUrl = {
	[key in string]?: TFragmentedTextAndAudioUrl;
};

export enum DocumentStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum LessonTypeEnum {
	MANDATORY = 'MANDATORY',
	BONUS = 'BONUS',
}

type TTopic = {
	id: string;
	title?: TLanguage;
	adminCode: string;
	status: ContentDocumentStatusEnum;
	description?: TLanguage;
	rankDecimal?: number;
	rankNumber?: number;
	coverImage?: TEmbeddedMedia;
	level?: number;
};

type TCleanTopic = TTopic & {
	id: string;
};

type TEmbeddedSubtopic = {
	id: string;
	displayName: string;
};

export enum MediaTypeEnum {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	PDF = 'PDF',
	AUDIO = 'AUDIO',
}

export enum PlayUiTypeEnum {
	VOCAB = 'VOCAB',
	GRAMMAR = 'GRAMMAR',
	READING = 'READING',
	ROLEPLAY = 'ROLEPLAY',
	CALL = 'CALL',
	SPEAK = 'SPEAK',
	GRAMMAR_GAME = 'GRAMMAR_GAME',
	CROSSWORD_GAME = 'CROSSWORD_GAME',
}

type TEmbeddedMedia = {
	mediaId?: string;
	mediaType: MediaTypeEnum;
	mediaPath: string;
	aspectRatio?: string;
	duration?: number;
	thumbnailUrl?: string;
	description?: string;
	prompt?: string;
};

type TLanguage = {
	[key in string]?: string;
};

type TLessonInfo = {
	title?: TLanguage;
	description?: TLanguage;
	coverImage?: TEmbeddedMedia;
};

type TCourse = {
	id: string;
	title: TLanguage;
	adminCode: string;
	status: ContentDocumentStatusEnum;
	description?: string;
};

type TRawCourse = TCourse & {
	_id: string;
};

type TCleanCourse = TCourse & {
	id: string;
};

export type TLesson = {
	adminCode: string;
	rankNumber?: number;
	rankDecimal?: number;
	course?: TCourse;
	topic?: TTopic;
	subtopic?: TSubtopic;
	questions: Array<TQuestion>;
	status: ContentDocumentStatusEnum;
	createdBy?: string;
	updatedBy?: string;
	uiType?: UiTypeEnum;
	info?: TLessonInfo;
};

export type TCourses = {
	id: string;
	name: string;
	description: string;
	need?: string;
};

export enum QuestionUiTypeEnum {
	VOCAB = 'VOCAB',
	SENTENCE = 'SENTENCE',
	DEFAULT = 'DEFAULT',
}

export enum QuestionTypesEnum {
	ANAGRAM = 'ANAGRAM',
	TEXT = 'TEXT',
	MATCH_COLUMN = 'MATCH_COLUMN',
	MCQ = 'MCQ',
	READ_ALONG = 'READ_ALONG',
	CONTENT_ONLY = 'CONTENT_ONLY',
	MEDIA = 'MEDIA',
	CONVERSATION = 'CONVERSATION',
	TELEPROMPTER = 'TELEPROMPTER',
	CROSSWORD = 'CROSSWORD',
}

type TMcqOption = {
	text: TMultilingualTextAndAudioUrl;
	media?: TEmbeddedMedia;
	isCorrectAnswer?: boolean;
};

export enum ContentDocumentStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DRAFT = 'DRAFT',
}

type TBaseLessonQuestion = {
	id: string;
	type: QuestionTypesEnum;
	adminCode: string;
	title: {
		[key: string]: any;
	};
	subtitle: {
		[key: string]: any;
	};
	descriptionImage?: TEmbeddedMedia;
	status: ContentDocumentStatusEnum;
	topic?: TCleanTopic;
	subtopic?: TEmbeddedSubtopic;
	voiceId?: string;
};

export type TMcqLessonQuestion = TBaseLessonQuestion & {
	type: QuestionTypesEnum.MCQ;
	options: Array<TMcqOption>;
	questionMapping?: string;
	correctSentence?: TMultilingualTextAndAudioUrl;
};

export type TConversationLessonQuestion = TBaseLessonQuestion & {
	type: QuestionTypesEnum.CONVERSATION;
	scene: TMultilingualTextAndAudioUrl;
	systemPrompt: string;
	userPrompt?: string;
	botName: string;
	firstBotDialogue: TMultilingualTextAndAudioUrl;
	firstBotDialogueHint: TMultilingualTextAndAudioUrl;
	systemVoiceId?: string;
	botVoiceId?: string;
	gptModel?: GPTModels;
	maxLength?: number;
};

export enum GPTModels {
	GPT_4O = 'GPT_4O',
	GPT_4O_MINI = 'GPT_4O_MINI',
	GPT_4 = 'GPT_4',
	GPT_3_5_TURBO = 'GPT_3_5_TURBO',
}

export enum AnagramTypesEnum {
	WORD = 'WORD',
	SENTENCE = 'SENTENCE',
}

export type TContentOnlyLessonQuestion = TBaseLessonQuestion & {
	type: QuestionTypesEnum.CONTENT_ONLY;
	roleName?: string;
	partsOfSpeech?: string;
	meaning?: TMultilingualTextAndAudioUrl;
};

export type Breadcrumb = {
	text: string;
	url?: string;
};

export type TReadAlongLessonQuestion = TBaseLessonQuestion & {
	type: QuestionTypesEnum.READ_ALONG;
	completeSentence?: TMultilingualTextAndAudioUrl;
};

export type TCrosswordsLessonQuestion = TBaseLessonQuestion & {
	type: QuestionTypesEnum.CROSSWORD;
	level?: number;
};

export type TQuestion =
	| TMcqLessonQuestion
	| TContentOnlyLessonQuestion
	| TConversationLessonQuestion
	| TReadAlongLessonQuestion
	| TCrosswordsLessonQuestion;

export type TLessonsState = {
	lesson?: TLesson;
	questions?: Array<TQuestion>;
	additionalData?: any;
	isLoading: boolean;
	error: string;
	page: number;
	total: number;
	isSubmitting: boolean;
	apiParams: {
		limit: number;
		skip: number;
		query?: string;
		sortKey: string;
		sortOrder: '-1' | '1';
		filters?: Record<string, string>;
	};
};
